import { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};
  //  background: url(images/bg.jpg) no-repeat center;
      background: url('/images/arch-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.jpg') no-repeat center;
   background-size: cover;
   min-height: 100vh;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
